import { useAuth } from '#imports'
import type { User, Pagination } from '~/types'
import * as Sentry from '@sentry/vue'
import { UserRole } from '~/enums'

interface State {
  data: User,
  items: User[]
  pagination: Pagination,
  pending: boolean
}

export const useUserStore = defineStore('user', {
  state: (): State => {
    return <State>{
      data: {
        id: 0,
        phone: '',
        status: 'active',
        last_name: null,
        first_name: null,
        middle_name: null,
        image: {}
      },
      items: [] as User[],
      pagination: {} as Pagination,
      pending: false
    }
  },
  getters: {
    name(): string {
      return this.firstLastName ?? this.data?.phone ?? ''
    },
    firstLastName: (state) => {
      const tmp: string[] = []
      if (state.data?.first_name) {
        tmp.push(state.data.first_name)
      }
      if (state.data?.last_name) {
        tmp.push(state.data.last_name)
      }
      return tmp.length ? tmp.join(' ') : undefined
    },
    isSuperAdmin(state): boolean {
      return state.data?.roles?.includes(UserRole.SuperAdmin)
    },
    isAdmin(state): boolean {
      return this.isSuperAdmin || state.data?.roles?.includes(UserRole.Admin)
    },
    isWriter(state): boolean {
      return state.data?.roles?.includes(UserRole.Writer) && state.data?.roles?.length === 1
    },
    role(state): string {
      if (this.isSuperAdmin) {
        return UserRole.SuperAdmin
      }
      if (this.isAdmin) {
        return UserRole.Admin
      }
      return state.data.roles[0]
    }
  },
  actions: {
    init() {
      const { data } = useAuth()
      const user = data.value as unknown as User
      if (user) {
        this.data = user

        Sentry.setUser({
          id: user.id,
          username: this.firstLastName,
          phone: user.phone,
          last_name: user.last_name,
          first_name: user.first_name,
          roles: user.roles,
          permissions: user.permissions
        })
      }
    },
    show(id: string) {
      return useAPIFetch<User>('/v1/users/' + id, {})
    },
    create(data: any) {
      return useAPIFetch('/v1/users', {
        method: 'POST',
        body: data,
      })
    },
    update(id: number, data: any) {
      return useAPIFetch('/v1/users/' + id, {
        method: 'POST',
        body: data,
      })
    },
    delete(id: number) {
      return useAPIFetch('/v1/users/' + id, {
        method: 'DELETE',
      })
    }
  },
})
