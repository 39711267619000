import { useAuthState } from '#imports'
import { useFormHelper } from '~/composables/helpers/useFormHelper'

export const useAPIFetch = async <T>(path: string, options: any = {}) => {
  const config = useRuntimeConfig()
  const { token} = useAuthState()
  const formHelper = useFormHelper()

  let headers = {}
  if (token.value) {
    headers = {
      Authorization: token.value
    }
  }

  const apiFetch = $fetch.create({
    baseURL: config.public.baseURL,
    headers
  })

  if (options.body) {
    options.body = formHelper.serialize(options.body, {
      dotsForObjectNotation: false,
      nullsAsUndefineds: true,
      indices: true,
    })
  }

  return apiFetch<T>(path, options)
}
