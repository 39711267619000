import { default as updateThpFoSUNTUMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/ads/[id]/update.vue?macro=true";
import { default as create9MmOhztyMmMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/ads/create.vue?macro=true";
import { default as indexjp0LzsQC7LMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/ads/index.vue?macro=true";
import { default as indexlfhiJBVnR5Meta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/[id]/index.vue?macro=true";
import { default as updateTuStSJFMKLMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/[id]/update.vue?macro=true";
import { default as createhIe3LOY3YyMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/create.vue?macro=true";
import { default as indexHNqPeyWyg9Meta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/index.vue?macro=true";
import { default as signinSZ1ksx3eWLMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/auth/signin.vue?macro=true";
import { default as updaterYIxt6N5qPMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/banners/[id]/update.vue?macro=true";
import { default as createVWQ3SX3vVVMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/banners/create.vue?macro=true";
import { default as indexS60Mx8FIonMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/banners/index.vue?macro=true";
import { default as callsUifXdmiFZiMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/dashboard/calls.vue?macro=true";
import { default as indexKK63UUJE89Meta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/[id]/index.vue?macro=true";
import { default as updatew4QZydCcqqMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/[id]/update.vue?macro=true";
import { default as createGgczDNYfyzMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/create.vue?macro=true";
import { default as indexuFkYWiMx27Meta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/index.vue?macro=true";
import { default as updateHYAVFOaKIzMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/cities/[id]/update.vue?macro=true";
import { default as create9UmSwozKXvMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/cities/create.vue?macro=true";
import { default as indexOfUy1xfRDMMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/cities/index.vue?macro=true";
import { default as updatelyuvotOHBXMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/regions/[id]/update.vue?macro=true";
import { default as createSvTr8OKjIkMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/regions/create.vue?macro=true";
import { default as indexgMilqEEz0hMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/regions/index.vue?macro=true";
import { default as inbox9CgaaiKyVDMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/inbox.vue?macro=true";
import { default as index91BBIv4MHHMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/index.vue?macro=true";
import { default as indexeMzFM06SUJMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/[id]/index.vue?macro=true";
import { default as updatejtvyY5SjlbMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/[id]/update.vue?macro=true";
import { default as createEAuZ8MvZNTMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/create.vue?macro=true";
import { default as indexyEzD89KLkvMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/index.vue?macro=true";
import { default as indexEULbeBQVGVMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/profile/index.vue?macro=true";
import { default as settingsve7TVn1mLRMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/profile/settings.vue?macro=true";
import { default as profileSDjABww4GaMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/profile.vue?macro=true";
import { default as indexfGVQ0LrDIuMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/settings/index.vue?macro=true";
import { default as memberseYFUvzTN6HMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/settings/members.vue?macro=true";
import { default as notificationsmtXVcFMQH9Meta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/settings/notifications.vue?macro=true";
import { default as updateFbyVipfs5XMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/tags/[id]/update.vue?macro=true";
import { default as create9z2TOZVMPqMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/tags/create.vue?macro=true";
import { default as indexerY64MFLkwMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/tags/index.vue?macro=true";
import { default as testJpIFfTVxu9Meta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/test.vue?macro=true";
import { default as update54niue2wZPMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/users/[id]/update.vue?macro=true";
import { default as create3DRXzsrXpTMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/users/create.vue?macro=true";
import { default as indexItVQfnIk0dMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/users/index.vue?macro=true";
import { default as indexNgv2X6MFaRMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/[id]/index.vue?macro=true";
import { default as updateElTII3BLZsMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/[id]/update.vue?macro=true";
import { default as create2LasVl2hJNMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/create.vue?macro=true";
import { default as indexcm18k8YSNiMeta } from "/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/index.vue?macro=true";
export default [
  {
    name: updateThpFoSUNTUMeta?.name ?? "ads-id-update___ru",
    path: updateThpFoSUNTUMeta?.path ?? "/ads/:id()/update",
    meta: updateThpFoSUNTUMeta || {},
    alias: updateThpFoSUNTUMeta?.alias || [],
    redirect: updateThpFoSUNTUMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/ads/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create9MmOhztyMmMeta?.name ?? "ads-create___ru",
    path: create9MmOhztyMmMeta?.path ?? "/ads/create",
    meta: create9MmOhztyMmMeta || {},
    alias: create9MmOhztyMmMeta?.alias || [],
    redirect: create9MmOhztyMmMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/ads/create.vue").then(m => m.default || m)
  },
  {
    name: indexjp0LzsQC7LMeta?.name ?? "ads___ru",
    path: indexjp0LzsQC7LMeta?.path ?? "/ads",
    meta: indexjp0LzsQC7LMeta || {},
    alias: indexjp0LzsQC7LMeta?.alias || [],
    redirect: indexjp0LzsQC7LMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexlfhiJBVnR5Meta?.name ?? "agencies-id___ru",
    path: indexlfhiJBVnR5Meta?.path ?? "/agencies/:id()",
    meta: indexlfhiJBVnR5Meta || {},
    alias: indexlfhiJBVnR5Meta?.alias || [],
    redirect: indexlfhiJBVnR5Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updateTuStSJFMKLMeta?.name ?? "agencies-id-update___ru",
    path: updateTuStSJFMKLMeta?.path ?? "/agencies/:id()/update",
    meta: updateTuStSJFMKLMeta || {},
    alias: updateTuStSJFMKLMeta?.alias || [],
    redirect: updateTuStSJFMKLMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createhIe3LOY3YyMeta?.name ?? "agencies-create___ru",
    path: createhIe3LOY3YyMeta?.path ?? "/agencies/create",
    meta: createhIe3LOY3YyMeta || {},
    alias: createhIe3LOY3YyMeta?.alias || [],
    redirect: createhIe3LOY3YyMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/create.vue").then(m => m.default || m)
  },
  {
    name: indexHNqPeyWyg9Meta?.name ?? "agencies___ru",
    path: indexHNqPeyWyg9Meta?.path ?? "/agencies",
    meta: indexHNqPeyWyg9Meta || {},
    alias: indexHNqPeyWyg9Meta?.alias || [],
    redirect: indexHNqPeyWyg9Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/agencies/index.vue").then(m => m.default || m)
  },
  {
    name: signinSZ1ksx3eWLMeta?.name ?? "auth-signin___ru",
    path: signinSZ1ksx3eWLMeta?.path ?? "/auth/signin",
    meta: signinSZ1ksx3eWLMeta || {},
    alias: signinSZ1ksx3eWLMeta?.alias || [],
    redirect: signinSZ1ksx3eWLMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: updaterYIxt6N5qPMeta?.name ?? "banners-id-update___ru",
    path: updaterYIxt6N5qPMeta?.path ?? "/banners/:id()/update",
    meta: updaterYIxt6N5qPMeta || {},
    alias: updaterYIxt6N5qPMeta?.alias || [],
    redirect: updaterYIxt6N5qPMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/banners/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createVWQ3SX3vVVMeta?.name ?? "banners-create___ru",
    path: createVWQ3SX3vVVMeta?.path ?? "/banners/create",
    meta: createVWQ3SX3vVVMeta || {},
    alias: createVWQ3SX3vVVMeta?.alias || [],
    redirect: createVWQ3SX3vVVMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/banners/create.vue").then(m => m.default || m)
  },
  {
    name: indexS60Mx8FIonMeta?.name ?? "banners___ru",
    path: indexS60Mx8FIonMeta?.path ?? "/banners",
    meta: indexS60Mx8FIonMeta || {},
    alias: indexS60Mx8FIonMeta?.alias || [],
    redirect: indexS60Mx8FIonMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/banners/index.vue").then(m => m.default || m)
  },
  {
    name: callsUifXdmiFZiMeta?.name ?? "dashboard-calls___ru",
    path: callsUifXdmiFZiMeta?.path ?? "/dashboard/calls",
    meta: callsUifXdmiFZiMeta || {},
    alias: callsUifXdmiFZiMeta?.alias || [],
    redirect: callsUifXdmiFZiMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/dashboard/calls.vue").then(m => m.default || m)
  },
  {
    name: indexKK63UUJE89Meta?.name ?? "developers-id___ru",
    path: indexKK63UUJE89Meta?.path ?? "/developers/:id()",
    meta: indexKK63UUJE89Meta || {},
    alias: indexKK63UUJE89Meta?.alias || [],
    redirect: indexKK63UUJE89Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updatew4QZydCcqqMeta?.name ?? "developers-id-update___ru",
    path: updatew4QZydCcqqMeta?.path ?? "/developers/:id()/update",
    meta: updatew4QZydCcqqMeta || {},
    alias: updatew4QZydCcqqMeta?.alias || [],
    redirect: updatew4QZydCcqqMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createGgczDNYfyzMeta?.name ?? "developers-create___ru",
    path: createGgczDNYfyzMeta?.path ?? "/developers/create",
    meta: createGgczDNYfyzMeta || {},
    alias: createGgczDNYfyzMeta?.alias || [],
    redirect: createGgczDNYfyzMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/create.vue").then(m => m.default || m)
  },
  {
    name: indexuFkYWiMx27Meta?.name ?? "developers___ru",
    path: indexuFkYWiMx27Meta?.path ?? "/developers",
    meta: indexuFkYWiMx27Meta || {},
    alias: indexuFkYWiMx27Meta?.alias || [],
    redirect: indexuFkYWiMx27Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/developers/index.vue").then(m => m.default || m)
  },
  {
    name: updateHYAVFOaKIzMeta?.name ?? "geo-cities-id-update___ru",
    path: updateHYAVFOaKIzMeta?.path ?? "/geo/cities/:id()/update",
    meta: updateHYAVFOaKIzMeta || {},
    alias: updateHYAVFOaKIzMeta?.alias || [],
    redirect: updateHYAVFOaKIzMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/cities/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create9UmSwozKXvMeta?.name ?? "geo-cities-create___ru",
    path: create9UmSwozKXvMeta?.path ?? "/geo/cities/create",
    meta: create9UmSwozKXvMeta || {},
    alias: create9UmSwozKXvMeta?.alias || [],
    redirect: create9UmSwozKXvMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/cities/create.vue").then(m => m.default || m)
  },
  {
    name: indexOfUy1xfRDMMeta?.name ?? "geo-cities___ru",
    path: indexOfUy1xfRDMMeta?.path ?? "/geo/cities",
    meta: indexOfUy1xfRDMMeta || {},
    alias: indexOfUy1xfRDMMeta?.alias || [],
    redirect: indexOfUy1xfRDMMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/cities/index.vue").then(m => m.default || m)
  },
  {
    name: updatelyuvotOHBXMeta?.name ?? "geo-regions-id-update___ru",
    path: updatelyuvotOHBXMeta?.path ?? "/geo/regions/:id()/update",
    meta: updatelyuvotOHBXMeta || {},
    alias: updatelyuvotOHBXMeta?.alias || [],
    redirect: updatelyuvotOHBXMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/regions/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createSvTr8OKjIkMeta?.name ?? "geo-regions-create___ru",
    path: createSvTr8OKjIkMeta?.path ?? "/geo/regions/create",
    meta: createSvTr8OKjIkMeta || {},
    alias: createSvTr8OKjIkMeta?.alias || [],
    redirect: createSvTr8OKjIkMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/regions/create.vue").then(m => m.default || m)
  },
  {
    name: indexgMilqEEz0hMeta?.name ?? "geo-regions___ru",
    path: indexgMilqEEz0hMeta?.path ?? "/geo/regions",
    meta: indexgMilqEEz0hMeta || {},
    alias: indexgMilqEEz0hMeta?.alias || [],
    redirect: indexgMilqEEz0hMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/geo/regions/index.vue").then(m => m.default || m)
  },
  {
    name: inbox9CgaaiKyVDMeta?.name ?? "inbox___ru",
    path: inbox9CgaaiKyVDMeta?.path ?? "/inbox",
    meta: inbox9CgaaiKyVDMeta || {},
    alias: inbox9CgaaiKyVDMeta?.alias || [],
    redirect: inbox9CgaaiKyVDMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/inbox.vue").then(m => m.default || m)
  },
  {
    name: index91BBIv4MHHMeta?.name ?? "index___ru",
    path: index91BBIv4MHHMeta?.path ?? "/",
    meta: index91BBIv4MHHMeta || {},
    alias: index91BBIv4MHHMeta?.alias || [],
    redirect: index91BBIv4MHHMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexeMzFM06SUJMeta?.name ?? "pages-id___ru",
    path: indexeMzFM06SUJMeta?.path ?? "/pages/:id()",
    meta: indexeMzFM06SUJMeta || {},
    alias: indexeMzFM06SUJMeta?.alias || [],
    redirect: indexeMzFM06SUJMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updatejtvyY5SjlbMeta?.name ?? "pages-id-update___ru",
    path: updatejtvyY5SjlbMeta?.path ?? "/pages/:id()/update",
    meta: updatejtvyY5SjlbMeta || {},
    alias: updatejtvyY5SjlbMeta?.alias || [],
    redirect: updatejtvyY5SjlbMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createEAuZ8MvZNTMeta?.name ?? "pages-create___ru",
    path: createEAuZ8MvZNTMeta?.path ?? "/pages/create",
    meta: createEAuZ8MvZNTMeta || {},
    alias: createEAuZ8MvZNTMeta?.alias || [],
    redirect: createEAuZ8MvZNTMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/create.vue").then(m => m.default || m)
  },
  {
    name: indexyEzD89KLkvMeta?.name ?? "pages___ru",
    path: indexyEzD89KLkvMeta?.path ?? "/pages",
    meta: indexyEzD89KLkvMeta || {},
    alias: indexyEzD89KLkvMeta?.alias || [],
    redirect: indexyEzD89KLkvMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profileSDjABww4GaMeta?.name ?? undefined,
    path: profileSDjABww4GaMeta?.path ?? "/profile",
    meta: profileSDjABww4GaMeta || {},
    alias: profileSDjABww4GaMeta?.alias || [],
    redirect: profileSDjABww4GaMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: indexEULbeBQVGVMeta?.name ?? "profile___ru",
    path: indexEULbeBQVGVMeta?.path ?? "",
    meta: indexEULbeBQVGVMeta || {},
    alias: indexEULbeBQVGVMeta?.alias || [],
    redirect: indexEULbeBQVGVMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: settingsve7TVn1mLRMeta?.name ?? "profile-settings___ru",
    path: settingsve7TVn1mLRMeta?.path ?? "settings",
    meta: settingsve7TVn1mLRMeta || {},
    alias: settingsve7TVn1mLRMeta?.alias || [],
    redirect: settingsve7TVn1mLRMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/profile/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexfGVQ0LrDIuMeta?.name ?? "settings___ru",
    path: indexfGVQ0LrDIuMeta?.path ?? "/settings",
    meta: indexfGVQ0LrDIuMeta || {},
    alias: indexfGVQ0LrDIuMeta?.alias || [],
    redirect: indexfGVQ0LrDIuMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: memberseYFUvzTN6HMeta?.name ?? "settings-members___ru",
    path: memberseYFUvzTN6HMeta?.path ?? "/settings/members",
    meta: memberseYFUvzTN6HMeta || {},
    alias: memberseYFUvzTN6HMeta?.alias || [],
    redirect: memberseYFUvzTN6HMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/settings/members.vue").then(m => m.default || m)
  },
  {
    name: notificationsmtXVcFMQH9Meta?.name ?? "settings-notifications___ru",
    path: notificationsmtXVcFMQH9Meta?.path ?? "/settings/notifications",
    meta: notificationsmtXVcFMQH9Meta || {},
    alias: notificationsmtXVcFMQH9Meta?.alias || [],
    redirect: notificationsmtXVcFMQH9Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: updateFbyVipfs5XMeta?.name ?? "tags-id-update___ru",
    path: updateFbyVipfs5XMeta?.path ?? "/tags/:id()/update",
    meta: updateFbyVipfs5XMeta || {},
    alias: updateFbyVipfs5XMeta?.alias || [],
    redirect: updateFbyVipfs5XMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/tags/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create9z2TOZVMPqMeta?.name ?? "tags-create___ru",
    path: create9z2TOZVMPqMeta?.path ?? "/tags/create",
    meta: create9z2TOZVMPqMeta || {},
    alias: create9z2TOZVMPqMeta?.alias || [],
    redirect: create9z2TOZVMPqMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/tags/create.vue").then(m => m.default || m)
  },
  {
    name: indexerY64MFLkwMeta?.name ?? "tags___ru",
    path: indexerY64MFLkwMeta?.path ?? "/tags",
    meta: indexerY64MFLkwMeta || {},
    alias: indexerY64MFLkwMeta?.alias || [],
    redirect: indexerY64MFLkwMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/tags/index.vue").then(m => m.default || m)
  },
  {
    name: testJpIFfTVxu9Meta?.name ?? "test___ru",
    path: testJpIFfTVxu9Meta?.path ?? "/test",
    meta: testJpIFfTVxu9Meta || {},
    alias: testJpIFfTVxu9Meta?.alias || [],
    redirect: testJpIFfTVxu9Meta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/test.vue").then(m => m.default || m)
  },
  {
    name: update54niue2wZPMeta?.name ?? "users-id-update___ru",
    path: update54niue2wZPMeta?.path ?? "/users/:id()/update",
    meta: update54niue2wZPMeta || {},
    alias: update54niue2wZPMeta?.alias || [],
    redirect: update54niue2wZPMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/users/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create3DRXzsrXpTMeta?.name ?? "users-create___ru",
    path: create3DRXzsrXpTMeta?.path ?? "/users/create",
    meta: create3DRXzsrXpTMeta || {},
    alias: create3DRXzsrXpTMeta?.alias || [],
    redirect: create3DRXzsrXpTMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexItVQfnIk0dMeta?.name ?? "users___ru",
    path: indexItVQfnIk0dMeta?.path ?? "/users",
    meta: indexItVQfnIk0dMeta || {},
    alias: indexItVQfnIk0dMeta?.alias || [],
    redirect: indexItVQfnIk0dMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgv2X6MFaRMeta?.name ?? "villages-id___ru",
    path: indexNgv2X6MFaRMeta?.path ?? "/villages/:id()",
    meta: indexNgv2X6MFaRMeta || {},
    alias: indexNgv2X6MFaRMeta?.alias || [],
    redirect: indexNgv2X6MFaRMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updateElTII3BLZsMeta?.name ?? "villages-id-update___ru",
    path: updateElTII3BLZsMeta?.path ?? "/villages/:id()/update",
    meta: updateElTII3BLZsMeta || {},
    alias: updateElTII3BLZsMeta?.alias || [],
    redirect: updateElTII3BLZsMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create2LasVl2hJNMeta?.name ?? "villages-create___ru",
    path: create2LasVl2hJNMeta?.path ?? "/villages/create",
    meta: create2LasVl2hJNMeta || {},
    alias: create2LasVl2hJNMeta?.alias || [],
    redirect: create2LasVl2hJNMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/create.vue").then(m => m.default || m)
  },
  {
    name: indexcm18k8YSNiMeta?.name ?? "villages___ru",
    path: indexcm18k8YSNiMeta?.path ?? "/villages",
    meta: indexcm18k8YSNiMeta || {},
    alias: indexcm18k8YSNiMeta?.alias || [],
    redirect: indexcm18k8YSNiMeta?.redirect,
    component: () => import("/var/www/app/www/admin.totdom.com/releases/20240906155519/pages/villages/index.vue").then(m => m.default || m)
  }
]