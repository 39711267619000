<script setup lang="ts">
import type { NuxtError } from '#app'
import {useI18n} from 'vue-i18n'

const { t } = useI18n({
  useScope: 'local'
})

const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true
  }
})

useHead({
  htmlAttrs: {
    lang: 'ru'
  }
})

useSeoMeta({
  title: String(props.error.statusCode),
  description: props.error.statusMessage
})

</script>

<template>
  <div>
    <UMain>
      <UContainer>
        <UPage>
          <UPageError
            :status="null"
            :name="String(error.statusCode)"
            :message="error.message"
            :clear-button="{ label: t('button.home') }"
          />
        </UPage>
      </UContainer>
    </UMain>

    <UNotifications />
  </div>
</template>

<i18n lang="json">
{
  "ru": {
    "button": {
      "home": "На главную"
    }
  }
}
</i18n>
